import { coreApp } from './coreAppApi';
import { panelApi } from './panelApi';
import { surveyApi } from './surveyApi';

export const apiReducers = {
  [coreApp.reducerPath]: coreApp.reducer,
  [panelApi.reducerPath]: panelApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
};

export const apiMiddleware = [
  coreApp.middleware,
  panelApi.middleware,
  surveyApi.middleware,
];

import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client"
import { Auth0Provider } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary'

import 'popper.js'
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { store } from './app/store';
import { App } from './App';

import { FallbackUI } from './components/shared/FallbackUI/FallbackUI';
import { returnAuth0ProviderConfig } from "./components/shared/helpers/returnAuth0ProviderConfig/returnAuth0ProviderConfig";

const root = document.getElementById('root') as HTMLElement;
const { domain, clientId, authorizationParams } = returnAuth0ProviderConfig();

createRoot(root).render(
  <ErrorBoundary FallbackComponent={FallbackUI}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </ErrorBoundary>
)

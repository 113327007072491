import { createApi } from '@reduxjs/toolkit/query/react';

import { CONFIG } from '../../appConfig';
import { baseQuery } from './baseApi';

// -------------------------------------------------------

export const panelApi = createApi({
  reducerPath: 'panelApi',
  baseQuery: baseQuery(CONFIG.api.panelBaseUrl),
  endpoints: () => ({}),
  tagTypes: [],
});

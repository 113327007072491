import {
  fetchBaseQuery,
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';
import { CONFIG } from '../../appConfig';

// -------------------------------------------------------

const getCommonHeaders = (
  token: string | null | undefined,
  subscriptionKey: string
) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
    headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
  }

  return headers;
};

const getUserIdentityHeaders = () => {
  const headers: Record<string, string> = {};

  const subAccountCustomerId = localStorage.getItem('selectedCustomerId');
  const impersonateCID = localStorage.getItem('ImpersonateCID');
  const impersonateUID = localStorage.getItem('ImpersonateUID');

  const shouldSetImpersonateHeaders =
    !subAccountCustomerId && impersonateCID && impersonateUID;

  const shouldSetCustomerIdHeader =
    subAccountCustomerId && !impersonateCID && !impersonateUID;

  if (shouldSetImpersonateHeaders) {
    headers['ImpersonateCID'] = impersonateCID;
    headers['ImpersonateUID'] = impersonateUID;
  }

  if (shouldSetCustomerIdHeader) {
    headers['rs-cid'] = subAccountCustomerId;
  }

  return headers;
};

// -------------------------------------------------------

export type BaseQueryError = {
  status: number;
  data: {
    message?: string;
    error?: string;
  };
};

const fetchWithHeaders = fetchBaseQuery({
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).tokenStateReducer;

    // Add common headers
    const commonHeaders = getCommonHeaders(token, CONFIG.subscriptionKey);
    Object.entries(commonHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });

    // Add user identity headers
    const userIdentityHeaders = getUserIdentityHeaders();
    Object.entries(userIdentityHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });

    return headers;
  },
});

export const baseQuery =
  (baseUrl: string): BaseQueryFn<FetchArgs, unknown, BaseQueryError> =>
  async (args: FetchArgs, api, extraOptions) => {
    const adjustedArgs = {
      ...args,
      url: baseUrl + '/' + args.url,
    };

    try {
      const result = await fetchWithHeaders(adjustedArgs, api, extraOptions);

      if (result.error) {
        return {
          error: {
            status: result.error.status,
            data: { message: getErrorMessage(result.error) },
          } as BaseQueryError,
        };
      }

      return result;
    } catch (error) {
      const err = error as FetchBaseQueryError;

      return {
        error: { status: err.status, data: err.data } as BaseQueryError,
      };
    }
  };

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
const isApiError = (
  error: unknown
): error is { status: number; data: { message?: string; error?: string } } => {
  return typeof error === 'object' && error != null && 'status' in error;
};

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
const isErrorWithMessage = (error: unknown): error is { message: string } => {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
};

export const getErrorMessage = (error: unknown): string => {
  if (isApiError(error)) {
    const errMsg =
      error.data?.message || error.data?.error || 'An error occurred';
    return errMsg;
  }

  if (isErrorWithMessage(error)) {
    return error.message;
  }

  return 'An unknown error occurred';
};

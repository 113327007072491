import adminReducer from 'store/reducers/adminReducer/adminReducer';
import projectsReducer from 'store/reducers/projectsReducer/projectsReducer';
import digStateReducer from 'store/reducers/digStateReducer/digStateReducer';
import userStateReducer from 'store/reducers/userStateReducer/userStateReducer';
import stateReducer from 'store/reducers/analyzeStateReducer/analyzeStateReducer';
import userSettingsReducer from 'store/reducers/userSettingsReducer/userSettingsReducer';
import chartStateReducer from 'store/reducers/chartStateReducer/chartStateReducer';
import recentDataReducer from 'store/reducers/recentDataReducer/recentDataReducer';
import panelStateReducer from 'store/reducers/panelStateReducer/panelStateReducer';
import qTableStateReducer from 'store/reducers/qTableStateReducer/qTableStateReducer';
import exportStateReducer from 'store/reducers/exportStateReducer/exportStateReducer';
import scriptStateReducer from 'store/reducers/scriptStateReducer/scriptStateReducer';
import toplineStateReducer from 'store/reducers/toplineStateReducer/toplineStateReducer';
import rawDataStateReducer from 'store/reducers/rawDataStateReducer/rawDataStateReducer';
import pinboardStateReducer from 'store/reducers/pinboardStateReducer/pinboardStateReducer';
import workflowStateReducer from 'store/reducers/workflowStateReducer/workflowStateReducer';
import breadcrumbStateReducer from 'store/reducers/breadcrumStateReducer/breadcrumbStateReducer';
import fileManagerStateReducer from 'store/reducers/fileManagerStateReducer/fileManagerStateReducer';
import setInitialDataReducer from 'store/reducers/analyzeInitialDataReducer/analyzeInitialDataReducer';
import surveyInitialDataReducer from 'store/reducers/surveyInitialDataReducer/surveyInitialDataReducer';
import errorMessageStateReducer from 'store/reducers/errorMessageStateReducer/errorMessageStateReducer';
import quickImportStatusReducer from 'store/reducers/quickImportStatusReducer/quickImportStatusReducer';
import advancedImportStatusReducer from 'store/reducers/advancedImportStatusReducer/advancedImportStatusReducer';
import inboxNotificationStateReducer from 'store/reducers/inboxNotificationStateReducer/inboxNotificationStateReducer';
import analysisScriptingStateReducer from 'store/reducers/analysisScriptingStateReducer/analysisScriptingStateReducer';
import actionNotificationStateReducer from 'store/reducers/actionNotificationStateReducer/actionNotificationStateReducer';
import surveyCustomQuestionsStateReducer from 'store/reducers/surveyCustomQuestionsStateReducer/surveyCustomQuestionsStateReducer';
import projectExternalShareStateReducer from 'store/reducers/projectExternalShareStateReducer/projectExternalShareStateReducer';
import reportStateReducer from 'store/reducers/reportStateReducer/reportStateReducer';
import audienceDataReducer from 'store/reducers/audienceAccessReducer/audienceAccessReducer';
import gatekeeperStateReducer from 'store/reducers/gatekeeperStateReducer/gatekeeperStateReducer';
import tokenStateReducer from 'store/reducers/tokenStateReducer/tokenStateReducer';
import analysisReportsReducer from 'store/reducers/analysisReportsStateReducer/analysisReportsStateReducer';

export const appReducers = {
  stateReducer: stateReducer,
  digStateReducer: digStateReducer,
  userStateReducer: userStateReducer,
  chartStateReducer: chartStateReducer,
  recentDataReducer: recentDataReducer,
  panelStateReducer: panelStateReducer,
  exportStateReducer: exportStateReducer,
  qTableStateReducer: qTableStateReducer,
  scriptStateReducer: scriptStateReducer,
  toplineStateReducer: toplineStateReducer,
  rawDataStateReducer: rawDataStateReducer,
  pinboardStateReducer: pinboardStateReducer,
  workflowStateReducer: workflowStateReducer,
  setInitialDataReducer: setInitialDataReducer,
  fileManagerStateReducer: fileManagerStateReducer,
  errorMessageStateReducer: errorMessageStateReducer,
  quickImportStatusReducer: quickImportStatusReducer,
  surveyInitialDataReducer: surveyInitialDataReducer,
  advancedImportStatusReducer: advancedImportStatusReducer,
  inboxNotificationStateReducer: inboxNotificationStateReducer,
  analysisScriptingStateReducer: analysisScriptingStateReducer,
  actionNotificationStateReducer: actionNotificationStateReducer,
  surveyCustomQuestionsStateReducer: surveyCustomQuestionsStateReducer,
  projectExternalShareStateReducer: projectExternalShareStateReducer,
  userSettingsReducer: userSettingsReducer,
  reportStateReducer: reportStateReducer,
  adminReducer: adminReducer,
  projectsReducer: projectsReducer,
  breadcrumbStateReducer: breadcrumbStateReducer,
  audienceDataReducer: audienceDataReducer,
  gatekeeperStateReducer: gatekeeperStateReducer,
  tokenStateReducer: tokenStateReducer,
  analysisReportsReducer: analysisReportsReducer,
};


import { addCustomRowToStructure } from "../../../components/Analysis/Analyze/components/shared/helpers/addCustomRowToStructure/addCustomRowToStructure";
import { deleteCustomRowFromStructure } from "../../../components/Analysis/Analyze/components/shared/helpers/deleteCustomRowFromStructure/deleteCustomRowFromStructure";
import { editCustomRowFromStructure } from "../../../components/Analysis/Analyze/components/shared/helpers/editCustomRowFromStructure/editCustomRowFromStructure";
import returnSearchData from "../../../components/Analysis/Analyze/components/shared/helpers/returnSearchData/returnSearchData";

const initialState = {
  theData: {
    theData: [],
    oldData: [],
    isSessionLoaded: false,
    defaultLanguage: [],
    languages: [],
    editingLanguage: [],
    showFullscreenResult: false,
    crossTabQuestionEditorCopyData: null,
    copyData: {
      source: null,
      elements: null
    }
  },
  structure: null
};
function setInitialDataReducer(state = initialState.theData, action) {
  switch (action.type) {
    case 'SET_INITIAL_DATA':
      return {
        ...state,
        theData: action.payload,
      };
    case 'SET_COPIED_ELEMENTS':
      return {
        ...state,
        copyData: action.payload
      }
    case 'ADD_CUSTOM_ROW_TO_STRUCTURE':
      return addCustomRowToStructure(state, action.payload)
    case 'UPDATE_CUSTOM_ROW_IN_STRUCTURE':
      return editCustomRowFromStructure(state, action.payload)
    case 'DELETE_CUSTOM_ROW_FROM_STRUCTURE':
      return deleteCustomRowFromStructure(state, action.payload)
    case 'SET_XT_QUESTION_EDITOR_COPY_DATA':
      return {
        ...state,
        crossTabQuestionEditorCopyData: action.payload,
      };
    case 'SET_SHOW_FULLSCREEN_RESULT':
      return {
        ...state,
        showFullscreenResult: action.payload,
      };
    case 'SET_LANGUAGES':
      return {
        ...state,
        languages: action.payload,
      };
    case 'SET_DEFAULT_LANGUAGE':
      return {
        ...state,
        defaultLanguage: action.payload,
      };
    case 'SET_EDITING_LANGUAGE':
      return {
        ...state,
        editingLanguage: action.payload,
      };
    case 'UPDATE_OLD_DATA':
      return {
        ...state,
        oldData: action.payload,
      };
    case 'SEARCH':
      return returnSearchData(state, action)
    case 'SELECT_CUSTOM_QUES':
      return {
        ...state,
        theData: action.payload
      }
    case 'SELECT_QUES_RANGE':
      return {
        ...state,
        theData: action.payload
      }
    case "SET_STRUCTURE":
      return {
        ...state,
        structure: action.payload
      }
    default:
      return state;
  }
}
export default setInitialDataReducer;

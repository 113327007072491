import { createApi } from '@reduxjs/toolkit/query/react';

import { CONFIG } from '../../appConfig';
import { baseQuery } from './baseApi';

// -------------------------------------------------------

export const coreApp = createApi({
  reducerPath: 'coreApp',
  baseQuery: baseQuery(CONFIG.api.appBaseUrl),
  endpoints: () => ({}),
  tagTypes: [],
});
